import type {
  CreateUpdateOrderDTO,
  IngridSession,
  KlarnaOrder,
  NexiRetrievePaymentResponse,
  OrderDTO
} from 'ecosystem'
import { Currency, CustomerType, OrderStatus } from 'ecosystem'
import { sumPropValues } from 'shared-utils'
import {
  transformAmountFromKlarnaToStorefront,
  transformOrderItemsToCreateOrderItems
} from '../../../utils/orders'
import {
  createEndpointError,
  createError,
  fetchPropsFactory,
  storefrontApiCall
} from '../../../utils/fetching'
import { ORDERS_ENDPOINT } from '../constants'
import { getTax } from '../../../utils/klarna-utils'
import { KLARNA_TAX_RATE } from '../../../utils/constants'
import { type OrderProvisionResult } from './types'

const generateBasePayload = (oldOrder: OrderDTO): CreateUpdateOrderDTO => {
  return {
    ...oldOrder,
    items: oldOrder.items ? transformOrderItemsToCreateOrderItems(oldOrder.items) : [],
    status: OrderStatus.PAID,
    currency: Currency.SEK
  }
}

const generateBasePayloadFromKlarna = (
  oldOrder: OrderDTO,
  klarnaOrderDetail: KlarnaOrder
): CreateUpdateOrderDTO => {
  return {
    ...generateBasePayload(oldOrder),
    billingAddress: {
      address1: klarnaOrderDetail.billing_address.street_address,
      address2: '',
      country: klarnaOrderDetail.billing_address.country,
      mobilePhone: klarnaOrderDetail.billing_address.phone,
      postalCode: klarnaOrderDetail.billing_address.postal_code,
      postalRegion: klarnaOrderDetail.billing_address.city,
      attention: `${klarnaOrderDetail.billing_address.given_name} ${klarnaOrderDetail.billing_address.family_name}`,
      reference: ''
    },
    customer: {
      email: klarnaOrderDetail.billing_address.email,
      firstName: klarnaOrderDetail.billing_address.given_name,
      lastName: klarnaOrderDetail.billing_address.family_name,
      type: CustomerType.CONSUMER
    },
    orderAmount: transformAmountFromKlarnaToStorefront(klarnaOrderDetail.order_amount),
    orderTaxAmount: transformAmountFromKlarnaToStorefront(klarnaOrderDetail.order_tax_amount),
    orderDiscount: transformAmountFromKlarnaToStorefront(
      sumPropValues(klarnaOrderDetail.order_lines, 'total_discount_amount')
    ),
    ...(klarnaOrderDetail.selected_shipping_option
      ? {
          shippingCost: transformAmountFromKlarnaToStorefront(
            klarnaOrderDetail.selected_shipping_option.price
          )
        }
      : {}),
    paymentId: klarnaOrderDetail.order_id,
    paymentProvider: 'KLARNA',
    shippingAddress: {
      address1: klarnaOrderDetail.shipping_address.street_address,
      address2: '',
      country: klarnaOrderDetail.shipping_address.country,
      mobilePhone: klarnaOrderDetail.shipping_address.phone,
      postalCode: klarnaOrderDetail.shipping_address.postal_code,
      postalRegion: klarnaOrderDetail.shipping_address.city,
      attention: `${klarnaOrderDetail.shipping_address.given_name} ${klarnaOrderDetail.shipping_address.family_name}`,
      reference: ''
    }
  }
}

const generateBasePayloadFromNexi = (
  oldOrder: OrderDTO,
  nexiResponse: NexiRetrievePaymentResponse
): CreateUpdateOrderDTO => {
  const { payment } = nexiResponse
  const { billingAddress, privatePerson, shippingAddress } = payment.consumer ?? {}
  const nexiItems = payment.charges?.[0].orderItems ?? []

  const { firstName = '', lastName = '', phoneNumber, email = '' } = privatePerson ?? {}

  const mobilePhone = `${phoneNumber?.prefix ?? ''}${phoneNumber?.number ?? ''}`
  const fullName = `${firstName} ${lastName}`

  const orderDiscount = nexiItems.reduce((sum, item) => {
    return item.reference === 'discount' ? sum + Math.abs(item.grossTotalAmount) : sum
  }, 0)

  const shippingCost = nexiItems.reduce((sum, item) => {
    return item.reference === 'delivery' ? sum + item.grossTotalAmount : sum
  }, 0)

  return {
    ...generateBasePayload(oldOrder),
    billingAddress: {
      address1: billingAddress?.addressLine1 ?? '',
      address2: '',
      country: billingAddress?.country ?? '',
      mobilePhone,
      postalCode: billingAddress?.postalCode ?? '',
      postalRegion: billingAddress?.city ?? '',
      attention: fullName,
      reference: ''
    },
    currency: payment.orderDetails.currency,
    customer: {
      email,
      firstName,
      lastName,
      type: CustomerType.CONSUMER
    },
    orderAmount: transformAmountFromKlarnaToStorefront(payment.orderDetails.amount),
    orderTaxAmount: transformAmountFromKlarnaToStorefront(
      getTax(payment.orderDetails.amount, KLARNA_TAX_RATE)
    ),
    orderDiscount: transformAmountFromKlarnaToStorefront(orderDiscount),
    shippingCost: transformAmountFromKlarnaToStorefront(shippingCost),

    paymentId: payment.paymentId,
    paymentProvider: 'NEXI',
    shippingAddress: {
      address1: shippingAddress?.addressLine1 ?? '',
      address2: '',
      country: shippingAddress?.country ?? '',
      mobilePhone,
      postalCode: shippingAddress?.postalCode ?? '',
      postalRegion: shippingAddress?.city ?? '',
      attention: fullName,
      reference: ''
    }
  }
}

// todo: add for LEDYER
const generateBasePayloadFromLedyer = (
  oldOrder: OrderDTO,
  _nexiResponse: any
): CreateUpdateOrderDTO => {
  return {
    ...generateBasePayload(oldOrder)
  }
}

const generatePayloadFromIngrid = (
  basePayload: CreateUpdateOrderDTO,
  ingridResponse?: IngridSession
) => {
  if (!ingridResponse) {
    return basePayload
  }

  /* eslint-disable camelcase -- need */
  const { delivery_groups } = ingridResponse
  const deliveryGroup = delivery_groups[0]
  const deliveryAddress = deliveryGroup.addresses.delivery_address
  const shipping = deliveryGroup.shipping

  return {
    ...basePayload,
    shippingCost: transformAmountFromKlarnaToStorefront(deliveryGroup.pricing.price),
    ...(deliveryAddress
      ? {
          shippingAddress: {
            address1: deliveryAddress.address_lines?.[0] || '',
            address2: '',
            country: deliveryAddress.country || '',
            mobilePhone: deliveryAddress.phone || '',
            postalCode: deliveryAddress.postal_code || '',
            postalRegion: deliveryAddress.city || '',
            attention: `${deliveryAddress.first_name} ${deliveryAddress.last_name}`,
            reference: `${shipping.carrier} ${shipping.delivery_type}, ${shipping.product}`
          }
        }
      : {})
  }
}

export async function completeStorefrontOrder({
  order: oldOrder,
  paymentProviderInfo,
  shippingProviderInfo
}: {
  order: OrderDTO
  paymentProviderInfo: OrderProvisionResult['paymentProviderResult']
  shippingProviderInfo?: OrderProvisionResult['shippingProviderResult']
}) {
  const shippingProviderName = shippingProviderInfo?.providerName ?? 'DEFAULT'
  let payload: CreateUpdateOrderDTO = generateBasePayload(oldOrder)

  switch (paymentProviderInfo?.providerName ?? 'KLARNA') {
    case 'KLARNA':
      if (!paymentProviderInfo?.klarnaResponse) {
        throw createError('Not found KLARNA order')
      }

      payload = generateBasePayloadFromKlarna(oldOrder, paymentProviderInfo.klarnaResponse)

      switch (shippingProviderName) {
        case 'DEFAULT':
          break
        case 'INGRID':
          payload = generatePayloadFromIngrid(payload, shippingProviderInfo?.ingridResponse)
      }
      break

    case 'NEXI':
      if (!paymentProviderInfo?.nexiResponse) {
        throw createError('Not found NEXI order')
      }

      payload = generateBasePayloadFromNexi(oldOrder, paymentProviderInfo.nexiResponse)

      switch (shippingProviderName) {
        case 'DEFAULT':
          break
        case 'INGRID':
          payload = generatePayloadFromIngrid(payload, shippingProviderInfo?.ingridResponse)
      }
      break

    case 'LEDYER': {
      if (!paymentProviderInfo?.ledyerResponse) {
        throw createError('Not found LEDYER order')
      }

      payload = generateBasePayloadFromLedyer(oldOrder, paymentProviderInfo.ledyerResponse)

      switch (shippingProviderName) {
        case 'DEFAULT':
          break
        case 'INGRID':
          payload = generatePayloadFromIngrid(payload, shippingProviderInfo?.ingridResponse)
      }
    }
  }

  const props = fetchPropsFactory({
    props: {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(payload)
    }
  })

  const { data: order, error } = await storefrontApiCall<OrderDTO>(ORDERS_ENDPOINT, props)

  if (error || !order) {
    return createEndpointError('update storefront order')
  }

  return order
}
