import {
  type IngridCompleteSessionResponse,
  type IngridSession,
  type NexiRetrievePaymentResponse,
  type Option,
  type OrderDeliveryProviderName,
  type OrderDTO
} from 'ecosystem'
import { getStorefrontOrderByOrderNumber } from '../getStorefrontOrderByOrderNumber'
import { ingridApi } from '../../../utils/indrid'
import { nexiApi } from '../../../utils/nexi'
import { createError } from '../../../utils/fetching'
import { completeStorefrontOrder } from './completeStorfrontOrder'

export interface ProvisionOrderNexiProviderResult {
  nexiResponse: NexiRetrievePaymentResponse
  storefrontOrder: Option<OrderDTO>
  shippingProviderResult?: {
    providerName: OrderDeliveryProviderName
    ingridResponse?: IngridCompleteSessionResponse['session']
  }
}

export const provisionOrderNexiProvider = async (
  paymentId: string
): Promise<Option<ProvisionOrderNexiProviderResult>> => {
  let storefrontOrder: Option<OrderDTO> = null
  let shippingProviderResult: ProvisionOrderNexiProviderResult['shippingProviderResult']

  // 1. Confirmation in Nexi
  const { error: nexiError, data: nexiPaymentResponse } =
    await nexiApi.checkout.retrievePayment(paymentId)

  if (nexiError || !nexiPaymentResponse) {
    throw Error(nexiError || 'Nexi GET endpoint error')
  }

  // 2. Checks if the order exists
  const { error: getOrderError, data: orderData } = await getStorefrontOrderByOrderNumber({
    orderNumber: nexiPaymentResponse.payment.orderDetails.reference
  })

  // 3. If the order doesn't exist, returns an error
  if (getOrderError || !orderData) {
    throw createError(getOrderError ?? 'BE GET endpoint error')
  }

  const completeStoreFrontOrderFn = async (shippingProviderInfo?: {
    providerName: OrderDeliveryProviderName
    ingridResponse?: IngridSession
  }) => {
    try {
      storefrontOrder = await completeStorefrontOrder({
        order: orderData,
        paymentProviderInfo: {
          providerName: 'NEXI',
          nexiResponse: nexiPaymentResponse
        },
        shippingProviderInfo
      })
      storefrontOrder = orderData
    } catch (e: any) {
      throw Error(e.message || 'BE PUT endpoint error')
    }

    if ('error' in storefrontOrder) {
      throw Error('BE PUT endpoint no order return')
    }
  }

  // 4. Get shipping provider information
  if (orderData.shippingId && orderData.shippingProvider) {
    switch (orderData.shippingProvider) {
      case 'INGRID':
        {
          const { data: ingridData, error: getIngridError } = await ingridApi.checkout.getSession(
            orderData.shippingId
          )

          if (getIngridError || !ingridData) {
            throw Error(getIngridError || 'getIngridSession error')
          }

          // 5. Complete storefront order, change status to PAID and update all info
          await completeStoreFrontOrderFn({
            providerName: orderData.shippingProvider,
            ingridResponse: ingridData.session
          })

          if (ingridData.session.status !== 'COMPLETE') {
            // 6. Complete ingrid session
            const { data: ingridCompleteResponse, error: completeIngridError } =
              await ingridApi.checkout.completeSession({
                checkout_session_id: orderData.shippingId,
                customer: ingridData.session.delivery_groups[0].addresses.customer,
                external_id: orderData.orderNumber || ''
              })

            if (completeIngridError || !ingridCompleteResponse) {
              throw Error(completeIngridError || 'completeIngridSession error')
            }

            shippingProviderResult = {
              providerName: orderData.shippingProvider,
              ingridResponse: ingridCompleteResponse.session
            }
          } else {
            shippingProviderResult = {
              providerName: orderData.shippingProvider,
              ingridResponse: ingridData.session
            }
          }
        }
        break
      case 'DEFAULT':
        await completeStoreFrontOrderFn()
    }
  } else {
    // 5. Complete storefront order, change status to PAID and update all info
    await completeStoreFrontOrderFn()
  }

  return {
    storefrontOrder,
    nexiResponse: nexiPaymentResponse,
    ...(shippingProviderResult && {
      shippingProviderResult
    })
  }
}
