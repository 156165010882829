import {
  type AssistantV2ChatRequestDTO,
  type AssistantV2ChatResponseDTO,
  type AssistantV2ThreadRequestDTO,
  type AssistantV2ThreadResponseDTO
} from 'ecosystem'
import { createError, fetchPropsFactory, storefrontApiCall } from '../utils/fetching'

const assistantEndpoint = `${process.env.NEXT_PUBLIC_API_HOST}/assistant`

export async function getAssistantV2ChatResponse(
  payload: AssistantV2ChatRequestDTO
): Promise<AssistantV2ChatResponseDTO | null> {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(payload)
    }
  })
  const url = `${assistantEndpoint}/v2/chat`

  const { data: assistantResponse, error } = await storefrontApiCall<AssistantV2ChatResponseDTO>(
    url,
    props
  )

  if (error) {
    throw createError(error)
  }

  return assistantResponse
}

export async function getAssistantV2Thread(
  payload: AssistantV2ThreadRequestDTO
): Promise<AssistantV2ThreadResponseDTO | null> {
  const props = fetchPropsFactory({
    props: {
      method: 'GET'
    }
  })
  const url = `${assistantEndpoint}/thread${payload.customerId ? `?customerId=${payload.customerId}` : ''}`

  const { data: assistantResponse, error } = await storefrontApiCall<AssistantV2ThreadResponseDTO>(
    url,
    props
  )

  if (error) {
    throw createError(error)
  }

  return assistantResponse
}
