import type {
  CreateOrderShippingConfig,
  DiscountDTO,
  KlarnaOrderLine,
  Language,
  ShippingOption,
  StorefrontOrderLine
} from 'ecosystem'
import { sumArrayOfNumbers } from 'shared-utils'
import type { ApiCallResponse } from './fetching'
import { apiCall } from './fetching'
import { EXTRA_FEE, KLARNA_TAX_RATE, MINIMUM_ORDER_AMOUNT } from './constants'

export const getTax = (totalAmount: number, taxRate: number) => {
  return totalAmount - (totalAmount * 10000) / (10000 + taxRate)
}

export const klarnaOrderLineFactory = (
  items: StorefrontOrderLine[],
  cartDiscountCode: DiscountDTO | null
) => {
  const productOrderLines = items.map((i) => mapStorefrontProductsIntoKlarna(i, cartDiscountCode))
  const serviceOrderLines = servicesOrderLineFactory(items)
  const orderLines = [...productOrderLines, ...serviceOrderLines]
  const orderAmount = sumArrayOfNumbers(
    orderLines.map((item: KlarnaOrderLine) => item.total_amount)
  )
  return { orderLines, orderAmount, productOrderLines }
}

export const servicesOrderLineFactory = (items: StorefrontOrderLine[]): KlarnaOrderLine[] => {
  return items
    .map((item) =>
      item.services.map((service) => {
        const quantity = service.quantity
        let unitPrice = service.service.price * 100
        const discountFactor = service.service.discountPrice * 100
        let totalDiscountAmount = 0
        let totalAmount = 0

        /*
          Basic formula for calculating price of service:
          service.price + service.discountPrice * (quantity - 1)
         */
        if (quantity > 0) {
          totalAmount = unitPrice + (quantity - 1) * discountFactor

          if (!unitPrice && discountFactor) {
            unitPrice = discountFactor
          }

          totalDiscountAmount = unitPrice * quantity - totalAmount
        }

        return {
          type: 'surcharge' as const,
          name: service.service.name,
          tax_rate: KLARNA_TAX_RATE,
          quantity,
          total_amount: totalAmount,
          unit_price: unitPrice,
          total_tax_amount: getTax(totalAmount, KLARNA_TAX_RATE),
          total_discount_amount: totalDiscountAmount,
          merchant_data: service.service.id
        }
      })
    )
    .flat()
}

// Use it if there is no third party shipping providers (like Ingrid)
export const shippingFactory = (
  orderAmount: number,
  locale: Language,
  opts?: CreateOrderShippingConfig['simpleShipping']
): ShippingOption => {
  const {
    minimumOrderAmountForFreeDelivery = MINIMUM_ORDER_AMOUNT,
    deliveryFee = EXTRA_FEE,
    labels
  } = opts || {}
  const isFree = orderAmount >= minimumOrderAmountForFreeDelivery * 100
  const quantity = 1
  const unitPrice = !isFree ? deliveryFee * 100 : 0
  const totalDiscountAmount = 0
  const totalAmount = quantity * unitPrice - totalDiscountAmount
  const totalTaxAmount = getTax(totalAmount, KLARNA_TAX_RATE)

  const localeDictionary = {
    en: {
      freeDeliveryLabel: 'Free - Delivery with telephone notification',
      paidDeliveryLabel: 'Shipping - Delivery with telephone notification'
    },
    sv: {
      freeDeliveryLabel: 'Gratis - Leverans med telefonavisering',
      paidDeliveryLabel: 'Frakt - Leverans med telefonavisering'
    },
    no: {
      freeDeliveryLabel: 'Gratis - Levering med telefonvarsel',
      paidDeliveryLabel: 'Frakt - Levering med telefonvarsel'
    },
    da: {
      freeDeliveryLabel: 'Gratis - Levering med telefonisk advisering',
      paidDeliveryLabel: 'Fragt - Levering med telefonisk advisering'
    }
  }

  const serviceLabel = labels ?? localeDictionary[locale]

  return {
    id: 'delivery',
    name: isFree ? serviceLabel.freeDeliveryLabel : serviceLabel.paidDeliveryLabel,
    price: unitPrice,
    tax_amount: totalTaxAmount,
    tax_rate: KLARNA_TAX_RATE
  }
}

export const orderLineValueCalculator = (
  orderLine: StorefrontOrderLine,
  cartDiscountCode: DiscountDTO | null
) => {
  const isDiscountCandidate =
    (orderLine.product.brand?.id &&
      cartDiscountCode?.brandIds?.includes(orderLine.product.brand.id)) ||
    cartDiscountCode?.categoryIds?.some((c) => orderLine.product.categoryIds?.includes(c)) ||
    cartDiscountCode?.tagIds?.some((t) =>
      orderLine.product.tags?.map((ptag) => ptag.id).includes(t)
    )

  const isOriginalPriceLower = orderLine.unitPrice > orderLine.originalPrice

  const quantity = orderLine.quantity
  let unitPrice = orderLine.unitPrice * 100
  let unitPriceWithDiscount = unitPrice
  let totalDiscountAmount = 0

  if (cartDiscountCode && isDiscountCandidate) {
    const discountRate = cartDiscountCode.value / 100

    if (!cartDiscountCode.campaign && !isOriginalPriceLower) {
      unitPrice = orderLine.originalPrice * 100
    }

    totalDiscountAmount = unitPrice * orderLine.quantity * discountRate
    unitPriceWithDiscount = unitPrice - unitPrice * discountRate
  }

  return {
    quantity,
    unitPrice,
    unitPriceWithDiscount,
    totalDiscountAmount,
    totalAmount: quantity * unitPrice - totalDiscountAmount
  }
}

export const mapStorefrontProductsIntoKlarna = (
  item: StorefrontOrderLine,
  cartDiscountCode: DiscountDTO | null
): KlarnaOrderLine => {
  const { quantity, totalDiscountAmount, totalAmount, unitPrice } = orderLineValueCalculator(
    item,
    cartDiscountCode
  )
  const totalTaxAmount = getTax(totalAmount, KLARNA_TAX_RATE)

  return {
    type: 'physical',
    name: item.product.name,
    tax_rate: KLARNA_TAX_RATE,
    quantity,
    total_amount: totalAmount,
    unit_price: unitPrice,
    total_tax_amount: totalTaxAmount,
    total_discount_amount: totalDiscountAmount,
    merchant_data: item.product.id
  }
}

interface KlarnaEndpointOptions<T> {
  orderId?: string
  payload?: T
}

type KlarnaApiCallResponse<T> = T | { error_code: string }

export const klarnaApiCall = async <T, K>(
  type: 'create' | 'get' | 'update' | 'acknowledge' | 'cancel',
  options: KlarnaEndpointOptions<K>
): Promise<ApiCallResponse<T>> => {
  const { orderId, payload } = options

  const klarnaEndpointDictionary = {
    create: {
      method: 'POST',
      url: `${process.env.KLARNA_URL}/checkout/v3/orders`
    },

    update: {
      method: 'POST',
      url: `${process.env.KLARNA_URL}/checkout/v3/orders/${orderId}`
    },

    get: {
      method: 'GET',
      url: `${process.env.KLARNA_URL}/checkout/v3/orders/${orderId}`
    },

    acknowledge: {
      method: 'POST',
      url: `${process.env.KLARNA_URL}/ordermanagement/v1/orders/${orderId}/acknowledge`
    },

    cancel: {
      method: 'POST',
      url: `${process.env.KLARNA_URL}/ordermanagement/v1/orders/${orderId}/cancel`
    }
  }

  const credentials = Buffer.from(
    `${process.env.KLARNA_USERNAME}:${process.env.KLARNA_PASSWORD}`
  ).toString('base64')
  const headers = { Authorization: `Basic ${credentials}`, 'Content-Type': 'application/json' }
  const props = {
    headers,
    method: klarnaEndpointDictionary[type].method,
    // optional body if payload
    ...(payload ? { body: JSON.stringify(payload) } : {})
  }

  const { error, data } = await apiCall<KlarnaApiCallResponse<T>>(
    klarnaEndpointDictionary[type].url,
    props
  )

  if (error || !data) {
    return {
      data: null,
      error
    }
  }

  // @ts-expect-error -- FIX
  if ('error_code' in data) {
    return {
      data: null,
      error: data.error_code
    }
  }

  return {
    data,
    error: null
  }
}
