import type {
  BundleDTO,
  CartItem,
  OldProductDTO,
  Option,
  ProductImages,
  ProductMedia,
  ServiceDTO
} from 'ecosystem'
import { ProductMediaType } from 'ecosystem'
import storefrontApi from '../storefront-api'
import { httpImageFactory } from './fetching'

/**  VARIANT GROUP PRODUCTS DETAILS FETCH */
export async function variantsFactory(
  variantGroupId: string | null
): Promise<OldProductDTO[] | null> {
  if (!variantGroupId) {
    return null
  }
  return storefrontApi.products.variantsByGroupId(variantGroupId)
}

/**  VARIANT GROUPS PRODUCTS DETAILS FETCH */
export async function variantsByIdsFactory(
  variantGroupIds?: (string | null)[]
): Promise<Option<Record<string, { data?: OldProductDTO[] | null; error?: string }>>> {
  const filteredIds = variantGroupIds?.filter((id) => typeof id === 'string') as
    | string[]
    | undefined

  if (!filteredIds?.length) {
    return null
  }

  const variantsByIds: Record<string, { data?: OldProductDTO[] | null; error?: string }> = {}

  await Promise.all(
    filteredIds.map((variantGroupId) =>
      storefrontApi.products
        .variantsByGroupId(variantGroupId)
        .then((res) => {
          variantsByIds[variantGroupId] = { data: res }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console -- show it in console when it builds
          console.error(err)
          variantsByIds[variantGroupId] = { error: err.message }
        })
    )
  )

  return variantsByIds
}

/**  BUNDLE PRODUCTS DETAILS FETCH */
export async function bundleProductsFactory(
  bundles: BundleDTO[] | null
): Promise<CartItem[] | null> {
  if (!bundles?.length) {
    return null
  }

  const bundleProductsResponse: CartItem[] = []

  for (const { productId, quantity } of bundles) {
    const product = await storefrontApi.products.productById(productId)

    if (product) {
      bundleProductsResponse.push({ ...product, qty: String(quantity) })
    }
  }

  return bundleProductsResponse
}

export const getProductImages = (medias: ProductMedia[] | null): ProductImages => {
  const mainImages = medias?.filter((mediaItem) => mediaItem.main)
  const secondaryImages = medias?.filter(
    (mediaItem) => !mediaItem.main && mediaItem.type === ProductMediaType.IMAGE
  )
  const mainImageUrl = httpImageFactory(
    medias && mainImages?.length ? mainImages[0].url : secondaryImages?.shift()?.url || ''
  )
  const secondaryImageUrl = httpImageFactory(secondaryImages?.shift()?.url || '')

  return {
    mainImages,
    secondaryImages,
    mainImageUrl,
    secondaryImageUrl
  }
}

export const getFullProduct = (cartItem: CartItem, services: Option<ServiceDTO[]>) => {
  const { qty, ...product } = cartItem
  const productQuantity = parseInt(qty || '1')
  return {
    product,
    quantity: productQuantity,
    services: services?.filter((s) => product.serviceIds?.includes(s.id)) || []
  }
}
